<template>
  <div>
    <top-nav :showLogo="false" :title="$t('邀请')"></top-nav>
    <div class="container">
      <div class="grade-cont">
        <img :src="getImageUrl(bank_level_img)">
      </div>
      <div class="invite-cont">
        <div class="title">
          {{ $t('我的邀请地址') }}
        </div>
        <div class="invite-add">
          {{ inviteUrl }}
        </div>
        <div class="reminder">
          {{ $t('温馨提示：贝灵数字人邀请链接只限于贝灵数字人使用， 贝灵王国各个应用会有独立邀请链接。') }}
        </div>

        <div class="copy-but" @click="copyLink(inviteUrl)">
          {{ $t('复制链接') }}
        </div>
      </div>

      <div class="title">
        {{ $t('邀请信息') }}
      </div>
      <div class="data-cont">
        <div class="row">
          <div class="key">{{ $t('潜在客户') }}</div>
          <div class="value">{{ inviteData.potential }}</div>
        </div>
        <div class="row">
          <div class="key">{{ $t('精准客户') }}</div>
          <div class="value">{{ inviteData.precise }}</div>
        </div>
        <div class="row">
          <div class="key">{{ $t('分行潜在储户') }}</div>
          <div class="value">{{ inviteData.branch_potential }}</div>
        </div>
        <div class="row">
          <div class="key">{{ $t('分行实际储户') }}</div>
          <div class="value">{{ inviteData.branch_reality }}</div>
        </div>
        <div class="row">
          <div class="key">{{ $t('分行储额(BUR)') }}</div>
          <div class="value">{{ inviteData.team_amount }}</div>
        </div>
<!--        <div class="row">-->
<!--          <div class="key">{{ $t('分行活期储额') }}</div>-->
<!--          <div class="value">321321321</div>-->
<!--        </div>-->
<!--        <div class="row">-->
<!--          <div class="key">{{ $t('分行定期储额') }}</div>-->
<!--          <div class="value">321321321</div>-->
<!--        </div>-->
      </div>

      <div class="title">
        {{ $t('我的邀请收益') }}
        <router-link class="record-but" to="/bank/inviteRecord">
          {{ $t('记录') }}
          <img class="right-arrow-icon" src="../../assets/image/bank/right-arrow-icon.png">
        </router-link>
      </div>

      <div class="data-cont">
        <div class="row">
          <div class="key">{{ $t('邀请总收益') }}</div>
          <div class="value">{{ alreadyRewards }}</div>
        </div>
        <div class="row">
          <div class="key">{{ $t('待领取收益') }}</div>
          <div class="value">{{ unclaimedRewards }}</div>
        </div>
        <div class="git-but" v-if="unclaimedRewards > 0" @click="claimReward">{{ $t('领取') }}</div>
      </div>

    </div>

    <div class="operation-success" v-if="showOperationSuccess">
      <div class="success-info-cont">
        <div class="success-info">
          <!--          <div class="title">{{ $t('绑定成功') }}</div>-->
          <!--          <div class="text">{{ invite_code }}</div>-->
          <div class="title"></div>
          <div class="text">{{ $t('领取成功') }}</div>
        </div>
        <img class="_bg" src="../../assets/image/index/success-bg.png">

        <div class="_but" @click="onShowActivationSuccess">
          {{ $t('确定') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import { inviteInfo } from "../../api"

export default {
  name: "invite",
  components: {TopNav},
  data() {
    return {
      alreadyRewards: 0,
      unclaimedRewards: 0,
      account: '',
      inviteUrl: '-',
      showOperationSuccess: false,
      bank_level_img: 'grade0.png',
      inviteData: {
        potential: 0,  // 潜在客户
        precise: 0,  // 精准客户
        branch_potential: 0, // 分行潜在储户
        branch_reality: 0,   // 分行实际储户
        team_amount: 0    // 分行储额
      }
    }
  },
  created() {
    this.getInviteData()
    this.getUserInfo()
    this.inviteInfo()
  },
  methods: {
    async getUserBind() {
      let that = this;
      await this.$store.dispatch('bur/getBinds', that.account)
          .then(res => {
            if (!res) {
              this.$router.push({
                path: '/bank/binding'
              })
            }
          })
    },
    async getInviteData() {
      let that = this;
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      await this.getAccount()
      await this.getUserBind()
      this.$store.dispatch('bur/initReward')
      that.alreadyRewards = await this.$store.dispatch('bur/getAlreadyRewards', this.account)
      that.unclaimedRewards = await this.$store.dispatch('bur/getUnclaimedRewards', this.account)

      console.log('account-------', this.account)
      console.log('getAlreadyRewards------', that.alreadyRewards)
      console.log('unclaimedRewards------', that.unclaimedRewards)

      this.$toast.clear()
    },
    async getAccount() {
      await this.$store.dispatch('bur/getAccount')
          .then(res => {
            if (res) {
              this.account = res
              this.inviteUrl = window.location.href + '?invite_code=' + res
            }
          })
    },
    copyLink(url) {
      let that = this
      this.$copyText(url).then(
          function (e) {
            that.$toast(that.$t('复制成功'));
          }
      );
    },
    claimReward() {
      let that = this;
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      this.$store.dispatch('bur/claimReward', this.account)
          .then(res => {
            console.log('claimReward-------', res);
            that.onShowActivationSuccess()
          })
          .catch((error) => {
            console.log(error);
            setTimeout(() => {
              that.$toast(that.$t("操作失败"));
            }, 300)
          })
          .finally(() => {
            that.$toast.clear()
          })

    },
    onShowActivationSuccess() {
      this.showOperationSuccess = !this.showOperationSuccess
      if (!this.showOperationSuccess) {
        this.getInviteData()
      }
    },
    getUserInfo() {
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      this.$store.dispatch('app/getUserInfo')
          .then((data) => {
            console.log('getUserInfo-----', data);
            if (data.code == 200) {
              this.bank_level_img = 'grade'+ data.data.bank_level +'.png'
              this.getImageUrl(this.bank_level_img)
              console.log('bank_level_img-----', this.bank_level_img)
            }
          })
          .finally(() => {
            this.$toast.clear()
          })
    },
    getImageUrl(filename) {
      return require(`../../assets/image/bank/grade/${filename}`);
    },
    inviteInfo() {
      inviteInfo()
          .then(({data}) => {
            console.log(data);
            if (data.code === 200) {
              this.inviteData = data.data
              console.log('inviteData---------', this.inviteData)
            }
          })
          .finally(() => {
            this.$toast.clear()
          })
    },
  }
}
</script>

<style scoped lang="less">
.container {
  padding: 1.5rem 4.5vw;
}

.grade-cont {
  padding-left: 1.5rem;
  margin-bottom: 1rem;

  img {
    height: 1.3rem;
  }
}

.title {
  padding-left: 1.5rem;
  font-size: 1.07rem;
  color: #E7BD71;
  margin-bottom: 1rem;
}

.invite-cont {
  .invite-add {
    padding: 0 0.8rem;
    height: 3.14rem;
    line-height: 3.14rem;
    font-size: 1rem;
    color: #E7BD71;
    background: #1B1B1B;
    border: 1px solid #262526;
    border-radius: 1.57rem;
    margin: 1.3rem 0 1rem 0;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .reminder {
    padding-left: 1.5rem;
    font-size: 0.86rem;
    font-weight: 400;
    color: #B5904E;
    line-height: 1.5rem;
  }

  .copy-but {
    font-size: 1.29rem;
    color: #000000;
    width: 80%;
    height: 3.14rem;
    text-align: center;
    line-height: 3.14rem;
    margin: 2rem auto 3rem;
    background: linear-gradient(-90deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.57rem;
  }
}

.data-cont {
  padding: 1.6rem 1.35rem;
  background: #1B1B1B;
  border-radius: 1.07rem;
  margin-bottom: 1.7rem;

  line-height: 2.14rem;

  .row {
    overflow: hidden;

    .key {
      float: left;
      font-size: 0.93rem;
      color: #6E6045;
    }

    .value {
      float: right;
      color: #E7BD71;
      font-size: 1.07rem;
    }

  }
}

.record-but {
  float: right;
  padding-right: 1.39rem;
  font-size: 0.79rem;
  color: #987534;

  .right-arrow-icon {
    padding-left: 0.3rem;
    width: 0.29rem;
    height: 0.57rem;
  }
}

.git-but {
  text-align: center;
  height: 3.14rem;
  margin-top: 2.2rem;
  color: #000;
  font-size: 1.29rem;
  line-height: 3.14rem;
  background: linear-gradient(-90deg, #E8BD70 0%, #EDD185 100%);
  border-radius: 1.57rem;
}
</style>
